$(window).load(function(){

	function init(){
		$('.mouse').click(function(e){
			e.preventDefault();
			$('html, body').animate({
		        scrollTop: $("#home").offset().top-$('#header .navbar').height()-20
		    }, 700);
		});

		mobileMenu();

	}

	function mobileMenu(){
		var $menu = $('.menu-mobile');

		$('.header-menu-mobile').click(function(e){
			e.preventDefault();
			$menu.toggleClass('hidden');

			$('body').css({'height': '100vh', 'overflow': 'hidden'});
		});

		$('.menu-mobile .close').click(function(e){
			e.preventDefault();
			$menu.toggleClass('hidden');
			$('body').css({'height': 'auto', 'overflow': 'auto'});
		});

		$(window).resize(function(){
			$menu.addClass('hidden');
			$('body').css({'height': 'auto', 'overflow': 'auto'});
		});
	}

	init();
});


